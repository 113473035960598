var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-factory-description" },
    [
      _c("div", { staticClass: "page-top" }, [
        _vm.showType == 1 || _vm.isEdit
          ? _c("div", [
              _c("div", { staticClass: "select-item" }, [
                _c("span", { staticClass: "select-title" }, [_vm._v("质检人")]),
                _c("span", { staticClass: "select-val none-select" }, [
                  _vm._v(_vm._s(_vm.name))
                ])
              ]),
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass: "select-item",
                      on: {
                        click: function($event) {
                          return _vm.selectItem("w")
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "select-title" }, [
                        _vm._v("故障类型")
                      ]),
                      _vm.warningType.name
                        ? _c("span", { staticClass: "select-val" }, [
                            _vm._v(_vm._s(_vm.warningType.name))
                          ])
                        : _c("span", { staticClass: "select-val empty" }, [
                            _vm._v("请选择故障类型")
                          ])
                    ]
                  )
                : _c("div", { staticClass: "select-item" }, [
                    _c("span", { staticClass: "select-title" }, [
                      _vm._v("故障类型")
                    ]),
                    _vm.warningType.name
                      ? _c("span", { staticClass: "select-val none-select" }, [
                          _vm._v(
                            _vm._s(_vm.warningType.name || "暂无") +
                              _vm._s(
                                _vm.info.reason &&
                                  _vm.warningType.name == "其他"
                                  ? "(" + _vm.info.factoryReason + ")"
                                  : ""
                              )
                          )
                        ])
                      : _c("span", { staticClass: "select-val none-select" }, [
                          _vm._v(_vm._s(_vm.warningType.name || "暂无"))
                        ])
                  ]),
              _vm.isEdit
                ? _c(
                    "div",
                    {
                      staticClass: "select-item",
                      on: {
                        click: function($event) {
                          return _vm.selectItem("c")
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "select-title" }, [
                        _vm._v("处理方法")
                      ]),
                      _vm.actionMethods.name
                        ? _c("span", { staticClass: "select-val" }, [
                            _vm._v(_vm._s(_vm.actionMethods.name))
                          ])
                        : _c("span", { staticClass: "select-val empty" }, [
                            _vm._v("请选择处理方法")
                          ])
                    ]
                  )
                : _c("div", { staticClass: "select-item" }, [
                    _c("span", { staticClass: "select-title" }, [
                      _vm._v("处理方法")
                    ]),
                    _c(
                      "span",
                      {
                        class: {
                          "select-val": true,
                          "none-select": !_vm.isEdit
                        }
                      },
                      [_vm._v(_vm._s(_vm.actionMethods.name || "超年限报废"))]
                    )
                  ]),
              _c("div", { staticClass: "page-footer" }, [
                _vm.isEdit
                  ? _c(
                      "div",
                      {
                        staticClass: "select-item",
                        on: {
                          click: function($event) {
                            return _vm.selectItem("z")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "select-title" }, [
                          _vm._v("责任方")
                        ]),
                        _vm.responsibilityObj.name
                          ? _c("span", { staticClass: "select-val" }, [
                              _vm._v(_vm._s(_vm.responsibilityObj.name))
                            ])
                          : _c("span", { staticClass: "select-val empty" }, [
                              _vm._v("请选择责任方")
                            ])
                      ]
                    )
                  : _c("div", { staticClass: "select-item" }, [
                      _c("span", { staticClass: "select-title" }, [
                        _vm._v("责任方")
                      ]),
                      _vm.responsibilityObj.name
                        ? _c(
                            "span",
                            {
                              class: {
                                "select-val": true,
                                "none-select": !_vm.isEdit
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.responsibilityObj.name || "暂无")
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                _vm._m(0),
                _vm.isEdit
                  ? _c(
                      "div",
                      { staticClass: "remark" },
                      [
                        _c("Field", {
                          attrs: {
                            rows: "5",
                            label: "备注",
                            type: "textarea",
                            placeholder: "请输入备注",
                            "aria-disabled": !_vm.isEdit,
                            "show-word-limit": ""
                          },
                          model: {
                            value: _vm.remark,
                            callback: function($$v) {
                              _vm.remark = $$v
                            },
                            expression: "remark"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "remark" }, [
                      _c("div", { staticClass: "remarm-text" }, [
                        _vm._v(_vm._s(_vm.remark || "暂无"))
                      ])
                    ]),
                _vm.isEdit
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      [
                        _c("Uploader", {
                          attrs: {
                            "max-size": 20000 * 200000,
                            "after-read": _vm.afterRead,
                            accept: "image/*",
                            "max-count": 8
                          },
                          model: {
                            value: _vm.fileList,
                            callback: function($$v) {
                              _vm.fileList = $$v
                            },
                            expression: "fileList"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isEdit && _vm.info.images && _vm.info.images.length
                  ? _c(
                      "div",
                      { staticClass: "img-list img-list-view" },
                      _vm._l(_vm.info.images, function(url, index) {
                        return _c(
                          "div",
                          {
                            key: url,
                            staticClass: "img-item",
                            on: {
                              click: function($event) {
                                return _vm.previewImg(index)
                              }
                            }
                          },
                          [_c("img", { attrs: { src: url, alt: "" } })]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.isEdit
                  ? _c("div", { staticClass: "select-item" }, [
                      _c("span", { staticClass: "select-title" }, [
                        _vm._v("质检时间")
                      ]),
                      _c("span", { staticClass: "select-val none-select" }, [
                        _vm._v(
                          _vm._s(_vm._f("getYMD")(_vm.info.factoryTransactAt))
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm.showType == 2
          ? _c("div", [
              !_vm.isEdit && _vm.info.status == 3
                ? _c("div", { staticClass: "select-item" }, [
                    _c("span", { staticClass: "select-title" }, [
                      _vm._v("览宋拒绝时间")
                    ]),
                    _c("span", { staticClass: "select-val none-select" }, [
                      _vm._v(_vm._s(_vm._f("getYMD")(_vm.info.transactAt)))
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "remark",
                  staticStyle: { "padding-bottom": "20px" }
                },
                [
                  _c("div", { staticStyle: { padding: "10px 0" } }, [
                    _vm._v("备注说明")
                  ]),
                  _c("Field", {
                    attrs: {
                      label: "备注",
                      type: "textarea",
                      placeholder: "暂无备注",
                      disabled: "",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.info.refuseReason,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "refuseReason", $$v)
                      },
                      expression: "info.refuseReason"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _vm.isEdit
        ? _c(
            "div",
            { staticClass: "btn-submit", on: { click: _vm.submitOrder } },
            [_vm._v("提交质检")]
          )
        : _vm._e(),
      _c("ActionSheet", {
        attrs: {
          actions: _vm.tagList,
          "cancel-text": "取消",
          "close-on-click-action": ""
        },
        on: { select: _vm.selectWarning },
        model: {
          value: _vm.warningType.show,
          callback: function($$v) {
            _vm.$set(_vm.warningType, "show", $$v)
          },
          expression: "warningType.show"
        }
      }),
      _c("ActionSheet", {
        attrs: {
          actions: _vm.methodsList,
          "cancel-text": "取消",
          "close-on-click-action": ""
        },
        on: { select: _vm.selectMethods },
        model: {
          value: _vm.actionMethods.show,
          callback: function($$v) {
            _vm.$set(_vm.actionMethods, "show", $$v)
          },
          expression: "actionMethods.show"
        }
      }),
      _c("ActionSheet", {
        attrs: {
          actions: _vm.responsibilityList,
          "cancel-text": "取消",
          "close-on-click-action": ""
        },
        on: { select: _vm.selectResponsibility },
        model: {
          value: _vm.responsibilityObj.show,
          callback: function($$v) {
            _vm.$set(_vm.responsibilityObj, "show", $$v)
          },
          expression: "responsibilityObj.show"
        }
      }),
      _c(
        "Popup",
        {
          staticStyle: { "min-height": "40%" },
          attrs: { position: "bottom", "close-on-click-overlay": false },
          model: {
            value: _vm.isShowPopup,
            callback: function($$v) {
              _vm.isShowPopup = $$v
            },
            expression: "isShowPopup"
          }
        },
        [
          _c("div", { staticClass: "pop-box" }, [
            _c("div", { staticClass: "pop-header" }, [
              _c(
                "div",
                { staticClass: "btn-back", on: { click: _vm.onclickPopBack } },
                [_vm._v("返回")]
              ),
              _c("div", { staticClass: "header-title" }, [_vm._v("其他原因")]),
              _c("div", { staticClass: "header-right" }, [_vm._v("返回")])
            ]),
            _c(
              "div",
              { staticClass: "pop-body" },
              [
                _c("Field", {
                  attrs: {
                    rows: "8",
                    type: "textarea",
                    placeholder: "请检单描述问题",
                    "aria-disabled": !_vm.isEdit,
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.reason,
                    callback: function($$v) {
                      _vm.reason = $$v
                    },
                    expression: "reason"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "pop-footer" }, [
              _c(
                "div",
                {
                  staticClass: "footer-btn",
                  on: { click: _vm.onclickClosePop }
                },
                [_vm._v("确定")]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select-item" }, [
      _c("span", { staticClass: "select-title" }, [_vm._v("质检图片和备注")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }